import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FacebookLogin from "react-facebook-login";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import profileImage from "../img/descarga.png";
const API_BASE_URL = process.env.REACT_APP_URL_API;

const LoginStatus = ({ event }) => {
  const location = useLocation();
  const [loggedIn, setLoggedIn] = useState(false); // Estado de inicio de sesión
  const [user, setUser] = useState(null); // Información del usuario
  const [errors, setErrors] = useState({});
  const [errors_re, setErrors_re] = useState({});
  const [showModal, setShowModal] = useState(false);
  console.log("user", user);
  const navigate = useNavigate(); // Hook de navegación
  const storedUserPhoto = localStorage.getItem("photo");
  const parsedUserPhoto = JSON.parse(storedUserPhoto);
  console.log("parsedUserPhoto",parsedUserPhoto)
  useEffect(() => {
    const storedUser = localStorage.getItem("user");

    if (storedUser) {
      setLoggedIn(true);
      const parsedUser = JSON.parse(storedUser);

      setName(parsedUser.name);
      setEmail(parsedUser.email);
      setUser({
        name: parsedUser.name,
        photo: parsedUserPhoto?.photo ?? "",
        email: parsedUser.email,
      });
    }
  }, [location.state]);

  const handleLogin = (e) => {
    e.preventDefault();
    //llamar al servicio de login
    setErrors({});
    const errors = {};
    if (!email.trim()) {
      errors.email = "Please enter your email";
    } else if (!isValidEmail(email)) {
      errors.email = "Please enter a valid email";
    }
    if (!password.trim()) errors.password = "Please enter your password";

    if (Object.keys(errors).length === 0) {
      fetch(`${API_BASE_URL}/login`, {
        method: "POST",
        body: JSON.stringify({ email: email, password: password }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            toast.error(data.msg);
          } else {
            // Lógica para iniciar sesión
            setErrors({});
            setEmail("");
            setPassword("");
            if (data.data.user.status != "A") {
              toast.error("Your account is not active");
            } else {
              setShowModal(false);
              if (data.data.token) {
                setLoggedIn(true);
                setUser({
                  name: data.data.user.full_name,
                  photo: `${API_BASE_URL}/see_photo?img=${data.data.user.photo}`,
                  email: data.data.user.email,
                  token: data.data.token,
                });
                localStorage.setItem(
                  "user",
                  JSON.stringify({
                    name: data.data.user.full_name,
                    email: data.data.user.email,
                    token: data.data.token,
                    nick: data.data.user.username,
                  })
                );
                if (data.data.user.photo != null)
                  localStorage.setItem(
                    "photo",
                    JSON.stringify({
                      photo: `${API_BASE_URL}/see_photo?img=${data.data.user.photo}`,
                    })
                  );

                setTimeout(() => {
                  window.location.reload();
                }, 4000);
              }
            }
          }
        })
        .catch((error) => {
          // Manejar cualquier error de la solicitud
          toast.error("An error has occurred");
          console.error("Error:", error);
        });
    } else {
      // Form is invalid, update the state with the errors
      setErrors(errors);
    }
  };

  const handleButtonClick = () => {
    setShowModal(true);
  };

  const [mostrarCuadro1, setMostrarCuadro1] = useState(true);
  const [mostrarCuadro2, setMostrarCuadro2] = useState(false);
  const [mostrarCuadro3, setMostrarCuadro3] = useState(false);

  const mostrarCuadro = (cuadro) => {
    if (cuadro === "cuadro1") {
      setMostrarCuadro1(true);
      setMostrarCuadro2(false);
      setMostrarCuadro3(false);
    } else if (cuadro === "cuadro2") {
      setMostrarCuadro1(false);
      setMostrarCuadro2(true);
      setMostrarCuadro3(false);
    } else if (cuadro === "cuadro3") {
      setMostrarCuadro1(false);
      setMostrarCuadro2(false);
      setMostrarCuadro3(true);
    }
  };

  const handleLogout = () => {
    // Lógica para cerrar sesión
    localStorage.removeItem("user");
    localStorage.removeItem("photo");
    localStorage.removeItem("searchTerm");
    localStorage.removeItem("idCat");
    setLoggedIn(false);
    setUser(null);
    window.location.reload();
  };

  const handleEditProfile = () => {
    // Lógica para editar perfil
    navigate("/MyProfile");
  };

  const handleRegister = (e) => {
    e.preventDefault();

    setErrors({});
    const errors_re = {};
    if (!email.trim()) {
      errors_re.email = "Please enter your email";
    } else if (!isValidEmail(email)) {
      errors_re.email = "Please enter a valid email";
    }
    if (!password.trim()) errors_re.password = "Please enter your password";
    if (!full_name.trim()) errors_re.full_name = "Please enter your full name";

    if (Object.keys(errors_re).length === 0) {
      localStorage.removeItem("user");
      localStorage.removeItem("photo");
      localStorage.removeItem("searchTerm");
      localStorage.removeItem("idCat");
      setLoggedIn(false);
      setUser(null);

      fetch(`${API_BASE_URL}/register`, {
        method: "POST",
        body: JSON.stringify({
          full_name: full_name,
          email: email,
          password: password,
          origin: "mipick",
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            toast.error(data.msg);
          } else {
            setErrors({});
            setName("");
            setEmail("");
            setPassword("");
            setUser({ name: full_name, photo: "", email: "email", token: "" });

            toast.success(
              "An email was sent to you to continue with account activation.",
              {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 5000,
              }
            );
            localStorage.setItem(
              "user",
              JSON.stringify({
                name: full_name,
                email: email,
                token: "",
              })
            );
            setTimeout(() => {
              window.location.reload();
            }, 5000);
          }
        })
        .catch((error) => {
          // Manejar cualquier error de la solicitud
          toast.error("An error has occurred");
          console.error("Error:", error);
        });
    } else {
      // Form is invalid, update the state with the errors
      setErrors_re(errors_re);
    }
  };

  const [email_forgot, setEmailForgot] = useState("");
  const handleForgotPassword = (e) => {
    e.preventDefault();
    setErrors({});
    const errors_re = {};
    if (!email_forgot.trim()) {
      errors_re.email_forgot = "Please enter your email";
    } else if (!isValidEmail(email_forgot)) {
      errors_re.email_forgot = "Please enter a valid email";
    }
    if (Object.keys(errors_re).length === 0) {
      fetch(`${API_BASE_URL}/link_password`, {
        method: "POST",
        body: JSON.stringify({ email: email_forgot }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            toast.error(data.msg);
          } else {
            toast.success(data.msg, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setErrors_re({});
            setEmailForgot("");
            setShowModal(false);
            //   navigate('/');
          }
        })
        .catch(() => {
          // Manejar cualquier error de la solicitud
          toast.error("An error has occurred");
        });
    } else {
      // Form is invalid, update the state with the errors
      setErrors_re(errors_re);
    }
  };

  const [full_name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    return emailRegex.test(email);
  };

  /*Facebook*/

  const responseFacebook = (response) => {
    if (response.error) {
      setLoggedIn(false);
    } else {
      if (response.accessToken) {
        setLoggedIn(true);

        setUser({
          facebook: true,
          name: response.name,
          photo: response.picture.data.url,
          email: response.email,
          token: response.accessToken,
        });
        localStorage.setItem(
          "user",
          JSON.stringify({
            name: response.name,
            email: response.email,
            token: response.accessToken,
            nick: response.graphDomain,
          })
        );
        localStorage.setItem(
          "photo",
          JSON.stringify({ photo: response.picture.data.url })
        );

        fetch(`${API_BASE_URL}/register`, {
          method: "POST",
          body: JSON.stringify({
            full_name: response.name,
            email: response.email,
            password: "qwerty",
            origin: response.graphDomain,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            if (response.status === 201 || response.status === 401) {
              setTimeout(() => {
                window.location.reload();
              }, 4000);
            }
          })

          .catch(() => {
            // Manejar cualquier error de la solicitud
            toast.error("An error has occurred upload");
          });
      }
    }
  };

  /*Google*/

  console.log("updateImageUrl", updateImageUrl);
  const responseGoogle = async (response) => {
    try {
      console.log("Google Response:", response);

      const decoded = jwt_decode(response);
      console.log("Decoded Token:", decoded);

      // Validate decoded token fields
      if (!decoded.email || !decoded.name || !decoded.picture) {
        console.error("Invalid token fields:", decoded);
        toast.error("Invalid Google response.");
        setLoggedIn(false);
        return;
      }

      // Set initial user state
      setLoggedIn(true);
      setUser({
        google: true,
        name: decoded.name,
        photo: decoded.picture,
        email: decoded.email,
        token: response,
      });

      // Save to localStorage
      localStorage.setItem(
        "user",
        JSON.stringify({
          name: decoded.name,
          email: decoded.email,
          token: response,
          nick: decoded.given_name,
        })
      );

      // Backend API call to register the user
      const payload = {
        full_name: decoded.name,
        email: decoded.email,
        password: "qwerty",
        origin: "google",
        photo: decoded.picture,
      };
      console.log("Payload to /register:", payload);

      const rawResponse = await fetch(`${API_BASE_URL}/register`, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log("Raw Response:", rawResponse);

      // Parse response JSON
      const data = await rawResponse.json();
      console.log("Parsed Data:", data);

      if (!data || (!data.status && !data?.msg ? data?.msg : data?.message)) {
        throw new Error("Unexpected backend response structure.");
      }

      // Resolve photo URL

      console.log("image-----------",data?.photo)
      const photoUrl = data?.photo === undefined ? decoded?.picture :
        data?.photo &&
        data?.photo !== "null" &&
        data?.photo !== undefined &&
        data?.photo.includes("/uploads")
        ? decoded?.picture
        : !data?.photo.includes("/null")
          ? `${API_BASE_URL}/see_photo?img=${data?.photo.split("uploads/")[1]}`
          : decoded?.picture

      console.log("Resolved Photo URL:", photoUrl);

      // Save photo to localStorage
      localStorage.setItem(
        "photo",
        JSON.stringify({
          photo: photoUrl,
        })
      );

      // Update user state
      setUser({
        google: true,
        name: decoded.name,
        photo: photoUrl,
        email: decoded.email,
        token: data.token,
      });

      // Handle response statuses
      if (data?.message !== undefined && data?.message === "user already exist") {
        console.log("start---------")
        toast.success("User registered successfully!");
        setTimeout(() => {
          window.location.reload(); // Only reload if absolutely necessary
        }, 4000);
      }else if (data?.msg !== undefined && data?.msg === "Successful registration") {
        console.log("start222222222---------")
        toast.success("User registered successfully!");
        setTimeout(() => {
          window.location.reload(); // Only reload if absolutely necessary
        }, 4000);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error has occurred while uploading");
      setLoggedIn(false);
    }
  };

  const handleButtonClickEvent = (eventName) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: eventName,
      event_category: "User",
      event_action: "Click",
      event_label: eventName,
    });
  };
  function updateImageUrl(url) {
    if (!url) {
      // Return an empty string if URL is undefined or null
      return "";
    }

    if (url.includes("uploads/https:")) {
      console.log("-----start1")
      // Correctly handle the case where URL contains "uploads/https:"
      return url.replace("http://api.mypick.is/profile/", "");
    } else if (url.startsWith("http:") ) {
      console.log("-----start2")
      // Handle cases where URL starts with "http:" or "https:"
      return url.replace("https://api.mypick.is/profile/", ""); // Adjust according to your actual URL
    } else {
      console.log("-----start3")
      // Handle default case for any other URLs
      return url.replace("profile/uploads", "profile"); // No real change here
    }
  }

  console.log("image", parsedUserPhoto?.photo);
  return (
    <div className="user-solo-movil">
      <nav>
        <ul>
          <li>
            {loggedIn ? (
              // Mostrar nombre y foto del usuario si ha iniciado sesión
              <div className="user-profile">
                <div className="d-inline-block dropdown">
                  <button
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="true"
                    className="dropdown-toggle"
                    onClick={() => handleButtonClickEvent("Profile")}
                  >
                    <span className="user-close-movil text-white mr-4 font-family-SpaceGrotesk-Bold">
                      {user.name}
                    </span>
                    <img
                      src={
                        parsedUserPhoto?.photo !== ""
                          ? updateImageUrl(parsedUserPhoto?.photo)
                          : { profileImage }
                      }
                    />
                  </button>
                  <div
                    tabIndex={-1}
                    role="menu"
                    aria-hidden="true"
                    className="dropdown-menu dropdown-menu-right"
                    x-placement="bottom-end"
                  >
                    <ul className="nav flex-column">
                      <li className="nav-item">
                        <a
                          className="dropdown-item"
                          href="Javascript:void(0)"
                          onClick={handleEditProfile}
                        >
                          My profile
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="dropdown-item"
                          href="Javascript:void(0)"
                          onClick={handleLogout}
                        >
                          <i className="far fa-sign-out"></i>Log out
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {event === "mypick" ? (
                  <button
                    data-toggle="modal"
                    data-target="#ModaLogin"
                    onClick={() => {
                      handleButtonClick();
                      handleButtonClickEvent("MyPicksSignIn_Up");
                    }}
                    className="btn-login font-family-SpaceGrotesk-Bold solo-login"
                  >
                    Sign In / Sign up
                  </button>
                ) : event === "bookmark" ? (
                  <button
                    data-toggle="modal"
                    data-target="#ModaLogin"
                    onClick={() => {
                      handleButtonClick();
                      handleButtonClickEvent("MyBookmarksSignIn_Up");
                    }}
                    className="btn-login font-family-SpaceGrotesk-Bold solo-login"
                  >
                    Sign In / Sign up
                  </button>
                ) : event === "signup" ? (
                  <button
                    data-toggle="modal"
                    data-target="#ModaLogin"
                    onClick={() => {
                      handleButtonClick();
                      handleButtonClickEvent("SignIn_Up");
                    }}
                    className="btn-login font-family-SpaceGrotesk-Bold solo-login"
                  >
                    Sign In / Sign up
                  </button>
                ) : (
                  ""
                )}
              </>
            )}
          </li>
        </ul>
      </nav>

      {showModal && (
        <div className="modal fade" id="ModaLogin">
          <div className="modal-dialog modal-md">
            <div className="modal-content">
              <div className="modal-body p-0">
                {mostrarCuadro1 && (
                  <div className="cuadro">
                    <div className="box-cuadro-modal">
                      <div className="row">
                        <div className="col-md-12 position-relative">
                          <h3 className="font-family-SpaceGrotesk-Bold text-white">
                            Sign in to your account
                          </h3>
                          <button
                            type="button"
                            className="close cerrar-modal"
                            data-dismiss="modal"
                          >
                            &times;
                          </button>
                        </div>
                        <div className="col-md-12">
                          <p className="font-family-SpaceGrotesk-Medium">
                            Sign in to your account to save, create and edit
                            your picks
                          </p>
                          <ToastContainer
                            position="top-center"
                            autoClose={8000}
                            closeOnClick
                            theme="dark"
                          />
                        </div>
                      </div>
                      <form onSubmit={handleLogin}>
                        {/* Campos de inicio de sesión */}
                        <div className="form-group">
                          <label>Email</label>
                          <input
                            type="email"
                            className={`form-control ${
                              errors.email ? "is-invalid" : ""
                            }`}
                            placeholder="Email Address"
                            id="email"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          <span className="icon far fa-envelope fa-lg"></span>
                          {errors.email && (
                            <div className="invalid-feedback">
                              {errors.email}
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <label>Contraseña</label>
                          <input
                            type={showPassword ? "text" : "password"}
                            id="password"
                            className={`form-control ${
                              errors.password ? "is-invalid" : ""
                            }`}
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Password"
                          />
                          <span className="icon far fa-lock-alt fa-lg"></span>
                          {errors.password && (
                            <div className="invalid-feedback">
                              {errors.password}
                            </div>
                          )}
                          <button
                            type="button"
                            className="icono"
                            onClick={handleTogglePassword}
                          >
                            {showPassword ? (
                              <i className="fas fa-eye-slash"></i>
                            ) : (
                              <i className="fas fa-eye"></i>
                            )}
                          </button>
                        </div>
                        <div className="form-group">
                          <button
                            onClick={() => mostrarCuadro("cuadro3")}
                            type="button"
                            className="btn-a font-family-SpaceGrotesk-Regular"
                          >
                            Forgot my password
                          </button>
                        </div>
                        <button
                          type="submit"
                          className="btn btn-login-modal font-family-SpaceGrotesk-Bold"
                        >
                          Sign In
                        </button>
                      </form>
                      <div className="row">
                        <div className="col-md-12 mt-4 mb-3 text-center">
                          <h4 className="text-gris-claro title-or d-inline-block">
                            O R
                          </h4>
                        </div>

                        <GoogleOAuthProvider clientId="951089599558-ss3is472v1vb57vd3e9gmqt5aeq6ag89.apps.googleusercontent.com">
                          <GoogleLogin
                            text="signin_with"
                            locale="en"
                            onSuccess={(credentialResponse) => {
                              responseGoogle(credentialResponse.credential);
                            }}
                            onError={() => {
                              console.log("Login Failed");
                            }}
                          />
                        </GoogleOAuthProvider>

                        {/*  {!loggedIn &&
                        <FacebookLogin
                          appId="172576561281270"
                          autoLoad={false}
                          fields="name,email,picture"                    
                          callback={responseFacebook}
                          textButton=" Sign In with Facebook"                       
                          icon="fa-facebook-square mr-2"
                          cssClass="btn-redes font-family-SpaceGrotesk-Bold" />
                       
                      } */}
                      </div>
                    </div>
                    <div className="cuadro-footer-modal font-family-SpaceGrotesk-Light">
                      Don’t Have An Account?
                      <button
                        onClick={() => mostrarCuadro("cuadro2")}
                        className="font-family-SpaceGrotesk-Bold"
                      >
                        Sign Up
                      </button>
                    </div>
                  </div>
                )}

                {mostrarCuadro2 && (
                  <div className="cuadro">
                    <div className="box-cuadro-modal">
                      <div className="row">
                        <div className="col-md-12 position-relative">
                          <h3 className="font-family-SpaceGrotesk-Bold text-white">
                            <button
                              onClick={() => mostrarCuadro("cuadro1")}
                              className="btn-back"
                            ></button>
                            Get your free account
                          </h3>
                          <button
                            type="button"
                            className="close cerrar-modal"
                            data-dismiss="modal"
                          >
                            &times;
                          </button>
                        </div>
                        <div className="col-md-12">
                          <p className="text-gray font-family-SpaceGrotesk-Medium">
                            Create a new account to save, create and edit your
                            picks
                          </p>
                          <ToastContainer
                            position="top-center"
                            autoClose={8000}
                            closeOnClick
                            theme="dark"
                          />
                        </div>
                      </div>
                      <form className="mt-2" onSubmit={handleRegister}>
                        {/* Campos de registro */}
                        <div className="form-group">
                          <label>Nombre completo</label>
                          <input
                            type="text"
                            placeholder="Complete name"
                            className={`form-control ${
                              errors_re.full_name ? "is-invalid" : ""
                            }`}
                            id="full_name"
                            name="full_name"
                            value={full_name}
                            onChange={(e) => setName(e.target.value)}
                          />
                          <span className="icon far fa-user fa-lg"></span>
                          {errors_re.full_name && (
                            <div className="invalid-feedback">
                              {errors_re.full_name}
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <label>Email</label>
                          <input
                            type="email"
                            placeholder="Email Address"
                            className={`form-control ${
                              errors_re.email ? "is-invalid" : ""
                            }`}
                            id="email"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          <span className="icon far fa-envelope fa-lg"></span>
                          {errors_re.email && (
                            <div className="invalid-feedback">
                              {errors_re.email}
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <label>Contraseña</label>
                          <input
                            type={showPassword ? "text" : "password"}
                            id="password"
                            name="password"
                            className={`form-control ${
                              errors_re.password ? "is-invalid" : ""
                            }`}
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                            placeholder="Password"
                          />
                          <span className="icon far fa-lock-alt fa-lg"></span>
                          {errors_re.password && (
                            <div className="invalid-feedback">
                              {errors_re.password}
                            </div>
                          )}
                          <button
                            type="button"
                            className="icono"
                            onClick={handleTogglePassword}
                          >
                            {showPassword ? (
                              <i className="fas fa-eye-slash"></i>
                            ) : (
                              <i className="fas fa-eye"></i>
                            )}
                          </button>
                        </div>
                        <button
                          type="submit"
                          className="btn btn-login-modal font-family-SpaceGrotesk-Bold"
                        >
                          Sign Up
                        </button>
                      </form>
                      <div className="row">
                        <div className="col-md-12 mt-4 mb-3 text-center">
                          <h4 className="text-gris-claro title-or d-inline-block">
                            O R
                          </h4>
                        </div>
                        <GoogleOAuthProvider clientId="951089599558-ss3is472v1vb57vd3e9gmqt5aeq6ag89.apps.googleusercontent.com">
                          <GoogleLogin
                            text="signup_with"
                            locale="en"
                            onSuccess={(credentialResponse) => {
                              responseGoogle(credentialResponse.credential);
                            }}
                            onError={() => {
                              console.log("Login Failed");
                            }}
                          />
                          ;
                        </GoogleOAuthProvider>
                        ;
                        {/*         {!loggedIn &&
                        <FacebookLogin
                          appId="172576561281270"
                          autoLoad={false}
                          fields="name,email,picture"                    
                          callback={responseFacebook}
                          textButton=" Continue with Facebook"                       
                          icon="fa-facebook-square mr-2"
                          cssClass="btn-redes font-family-SpaceGrotesk-Bold" />  
                       
                      } */}
                      </div>
                    </div>
                    <div className="cuadro-footer-modal font-family-SpaceGrotesk-Light">
                      Already Have An Account,
                      <button
                        onClick={() => mostrarCuadro("cuadro1")}
                        className="font-family-SpaceGrotesk-Bold"
                      >
                        Sign In
                      </button>
                    </div>
                  </div>
                )}

                {mostrarCuadro3 && (
                  <div className="cuadro">
                    <div className="box-cuadro-modal">
                      <div className="row">
                        <div className="col-md-12 position-relative">
                          <h3 className="font-family-SpaceGrotesk-Bold text-white">
                            <button
                              onClick={() => mostrarCuadro("cuadro1")}
                              className="btn-back"
                            >
                              <i className="far fa-arrow-left"></i>
                            </button>
                            Forgot my password
                          </h3>
                          <button
                            type="button"
                            className="close cerrar-modal"
                            data-dismiss="modal"
                          >
                            &times;
                          </button>
                        </div>
                        <div className="col-md-12">
                          <p className="font-family-SpaceGrotesk-Medium">
                            Enter your email address and we will send you a link
                            to create a new password.
                          </p>
                          <ToastContainer
                            position="top-right"
                            autoClose={8000}
                            closeOnClick
                            theme="dark"
                          />
                        </div>
                        <div className="col-md-12">
                          <form
                            className="mt-4"
                            onSubmit={handleForgotPassword}
                          >
                            <div className="form-group">
                              <label>Email</label>
                              <input
                                type="email"
                                placeholder="Email Address"
                                className={`form-control ${
                                  errors_re.email_forgot ? "is-invalid" : ""
                                }`}
                                id="email_forgot"
                                name="email_forgot"
                                value={email_forgot}
                                onChange={(e) => setEmailForgot(e.target.value)}
                              />
                              <span className="icon far fa-envelope fa-lg"></span>
                              {errors_re.email_forgot && (
                                <div className="invalid-feedback">
                                  {errors_re.email_forgot}
                                </div>
                              )}
                            </div>
                            <button
                              type="submit"
                              className="btn btn-login-modal font-family-SpaceGrotesk-Bold mt-4"
                            >
                              Send
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginStatus;
